import React from 'react';

const YouTubeVideo = ({ videoId, videoUrl, title, description }) => {
    return (
        <section 
            className="vid-section tf-live-auction visible-sw" 
            style={{ padding: '30px 15px' }} // Adjusted padding for better mobile support
        >
            <div 
                className="vid-container" 
                style={{ 
                    maxWidth: '1200px', 
                    margin: '0 auto', 
                    textAlign: 'center' 
                }}
            >
                <div 
                    style={{ 
                        position: 'relative', 
                        paddingBottom: '56.25%', // 16:9 Aspect Ratio
                        height: '0', 
                        overflow: 'hidden', 
                        maxWidth: '100%', 
                        borderRadius: '10px',
                    }}
                >
                    {videoId ? (
                        // YouTube Video Embed
                        <iframe
                            src={`https://www.youtube.com/embed/${videoId}?autoplay=1&loop=1&playlist=${videoId}&controls=0&mute=1`}
                            title="YouTube Video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                border: 0,
                                borderRadius: '10px',
                            }}
                        ></iframe>
                    ) : videoUrl ? (
                        // Direct Video Link
                        <video
                            autoPlay
                            loop
                            muted
                            playsInline
                            controls
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                border: 0,
                                borderRadius: '10px',
                                objectFit: 'cover',
                            }}
                        >
                            <source src={videoUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    ) : (
                        <p>No video source provided.</p>
                    )}
                </div>
            </div>

            {/* Optional Title and Description */}
            {/* {title && <h2 style={{ fontSize: '1.5rem', marginTop: '20px' }}>{title}</h2>}
            {description && <p style={{ fontSize: '1rem', color: '#666' }}>{description}</p>} */}
        </section>
    );
};

export default YouTubeVideo;
